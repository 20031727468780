import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PricingStore } from './pricing.store';
import { B2bPricingApiService } from '@conciliome/b2b-api';
import { PricingPage } from './pricing.model';

@Injectable({ providedIn: 'root' })
export class PricingService {
  constructor(private pricingStore: PricingStore, private pricingApiService: B2bPricingApiService) {}

  get(): Observable<PricingPage> {
    return this.pricingApiService.get().pipe(
      tap((pricing) => {
        this.pricingStore.updateContent(pricing);
      })
    );
  }
}
