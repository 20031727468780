<b2b-ui-header
  [isFormPage]="hasForm"
  [isSticky]="isHeaderSticky"
  [isStickyOnScroll]="isStickyOnScroll"
></b2b-ui-header>

<div class="breadcrumbs-wrapper" [fxHide]="!isHeaderSticky">
  <div class="container-xl pt-5">
    <b2b-ui-breadcrumbs></b2b-ui-breadcrumbs>
  </div>
</div>

<router-outlet></router-outlet>

<b2b-ui-footer [isFormPage]="hasForm"></b2b-ui-footer>
