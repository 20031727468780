import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';

import { B2bApiModule } from '@conciliome/b2b-api';
import { B2bUiRoutingModule } from './b2b-ui-routing.module';
import { B2bShellModule } from './b2b-shell/b2b-shell.module';
import { B2bTunnelShellModule } from './b2b-tunnel-shell/b2b-tunnel-shell.module';

registerLocaleData(localeFr);
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    B2bApiModule,
    B2bShellModule,
    B2bTunnelShellModule,
    B2bUiRoutingModule,
  ],
  declarations: [],
  exports: [],
})
export class B2bUiModule {}
