import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { PricingState, PricingStore } from './pricing.store';

@Injectable({ providedIn: 'root' })
export class PricingQuery extends Query<PricingState> {
  content$ = this.select(state => (state as PricingState).content );

  constructor(protected store: PricingStore) {
    super(store);
  }

}
