import { Component, Injector, OnInit } from '@angular/core';
import {
  HomepageQuery,
  HomepageService,
  HomepageStore,
  SharedService,
  SharedStore,
  TunnelsService,
  TunnelsStore,
} from '@b2b-app/b2b-core';
import { ConcilioComponent } from '@conciliome/concilio-core';

@Component({
  selector: 'b2b-ui-tunnel-shell',
  templateUrl: './b2b-tunnel-shell.component.html',
  styleUrls: ['./b2b-tunnel-shell.component.scss'],
})
export class B2bTunnelShellComponent extends ConcilioComponent implements OnInit {
  hasForm = true;
  isHeaderSticky = true;
  isStickyOnScroll = false;
  partenaires$ = this.homepageQuery.partenaires$;

  constructor(
    injector: Injector,
    private sharedService: SharedService,
    private sharedStore: SharedStore,
    private tunnelStore: TunnelsStore,
    private tunnelService: TunnelsService,
    private homepageStore: HomepageStore,
    private homepageQuery: HomepageQuery,
    private homepageService: HomepageService
  ) {
    super(injector);

    if (!this.sharedStore.getValue().loaded) {
      this.addSubscription(this.sharedService.get().subscribe());
    }

    if (!this.homepageStore.getValue().content) {
      this.addSubscription(this.homepageService.get().subscribe());
    }

    if (!this.tunnelStore.getValue().content) {
      this.addSubscription(this.tunnelService.get().subscribe());
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
