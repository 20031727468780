import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { UiCarouselComponent } from './ui-carousel.component';

@NgModule({
  declarations: [UiCarouselComponent],
  imports: [CommonModule, IvyCarouselModule],
  exports: [UiCarouselComponent],
  providers: [],
})
export class UiCarouselModule {}
