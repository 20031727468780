import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { B2bServicesState, B2bServicesStore } from './b2b-services.store';

@Injectable({ providedIn: 'root' })
export class B2bServicesQuery extends Query<B2bServicesState> {
  content$ = this.select(state => (state as B2bServicesState).content );

  constructor(protected store: B2bServicesStore) {
    super(store);
  }

}
