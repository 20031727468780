import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { HotToastModule } from '@ngneat/hot-toast';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { ReportToInstanaErrorHandler } from './errorHandlers/reportToInstana.errorHandler';
import { AppComponent } from './app.component';
import { COMPONENT_LIVECYCLE_DEBUG_MODE } from '@conciliome/concilio-core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { UiBreadcrumbsModule } from '@b2b-app/b2b-ui';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatSnackBarModule,

    UiBreadcrumbsModule.forRoot({ postProcess: null, applyDistinctOn: 'label' }),
    MarkdownModule.forRoot(),
    TranslateModule.forRoot(),
    HotToastModule.forRoot(),
    NgcCookieConsentModule.forRoot({
      cookie: {
        domain: environment.cookieDomain,
        expiryDays: 182,
      },
      position: 'bottom',
      theme: 'edgeless',
      showLink: false,
      palette: {
        popup: {
          background: '#000062',
          text: '#ffffff',
          link: '#ffffff',
        },
        button: {
          background: '#be0087',
          text: '#ffffff',
          border: 'transparent',
        },
      },
      // autoOpen: true,
      type: 'opt-out',
      content: {
        message: `Nous utilisons des cookies afin d'améliorer continuellement notre site Web. En confirmant le bouton «Accepter», vous acceptez l'utilisation de cookies.`,
        allow: 'Accepter',
        deny: 'Refuser',
        policy: 'Politique des cookies',
      },
    }),
    AppRoutingModule,

    environment.production ? [] : AkitaNgDevtools,
  ],
  providers: [
    { provide: ErrorHandler, useClass: ReportToInstanaErrorHandler },
    { provide: 'environment', useValue: environment },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500, position: 'center top' } },
    { provide: COMPONENT_LIVECYCLE_DEBUG_MODE, useValue: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
