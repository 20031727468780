import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { B2bTemoignagnesApiService } from '@conciliome/b2b-api';
import { TemoignageStore } from './temoignage.store';

@Injectable({ providedIn: 'root' })
export class TemoignageService {
  constructor(private temoignageStore: TemoignageStore, private temoignageApiService: B2bTemoignagnesApiService) {}

  get() {
    return this.temoignageApiService.get().pipe(
      tap((temoignage) => {
        this.temoignageStore.updateContent(temoignage);
      })
    );
  }

  getById(id: string) {
    return this.temoignageApiService.getById(id).pipe(
      tap((temoignage) => {
        this.temoignageStore.selectTemoignage(temoignage);
      })
    );
  }
}
