import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SharedState, SharedStore } from './shared.store';

@Injectable({ providedIn: 'root' })
export class SharedQuery extends Query<SharedState> {
  footer$ = this.select(state => (state as SharedState).footer );
  more_section$ = this.select(state => (state as SharedState).more_section );
  header$ = this.select(state => (state as SharedState).header );
  garanties$ = this.select(state => (state as SharedState).garanties );

  constructor(protected store: SharedStore) {
    super(store);
  }

}
