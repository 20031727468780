import { AfterViewInit, Component, forwardRef, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConcilioComponent } from '@conciliome/concilio-core';
import { HomepageQuery } from '@b2b-app/b2b-core';

@Component({
  selector: 'b2b-tunnel-general-infos',
  templateUrl: './general-infos.component.html',
  styleUrls: ['./general-infos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GeneralInfosComponent),
      multi: true,
    },
  ],
})
export class GeneralInfosComponent extends ConcilioComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  @Input() type: 'essai' | 'rdv' = 'rdv';
  @Input() ctaText = '';

  companyTypeArray = [
    { label: 'une entreprise', value: 'entreprise' },
    { label: 'une administration', value: 'administration' },
    { label: 'un CSE', value: 'cse' },
    { label: 'un indépendant', value: 'indépendant' },
  ];

  scopeArray = [];

  scopeEntrepriseArray = [
    { label: 'Tous vos salariés', value: 'tous' },
    { label: 'Une partie de vos salariés', value: 'partie' },
    { label: 'Votre comité de direction', value: 'cse' },
  ];

  scopeAdministrationArray = [
    { label: 'Tous vos salariés', value: 'tous' },
    { label: 'Une partie de vos salariés', value: 'partie' },
  ];
  infosForm: FormGroup = this.fb.group({
    companyTypeControl: new FormControl(''),
    scopeControl: new FormControl(''),
    numberControl: new FormControl(''),
    contactExpertB2bControl: new FormControl(''),
  });
  homepageContent$ = this.homepageQuery.content$;

  onChanges: any = (c) => {};
  onTouched: any = () => {};

  constructor(injector: Injector, private fb: FormBuilder, private homepageQuery: HomepageQuery) {
    super(injector);

    this.infosForm.controls['companyTypeControl'].valueChanges.subscribe((value) => {
      switch (value) {
        case this.companyTypeArray[0].value:
          this.scopeArray = this.scopeEntrepriseArray;
          this.infosForm.controls['scopeControl'].setValue(this.scopeEntrepriseArray[0].value);
          this.infosForm.controls['scopeControl'].enable();
          this.infosForm.controls['numberControl'].enable();

          break;
        case this.companyTypeArray[1].value:
          this.scopeArray = this.scopeAdministrationArray;
          this.infosForm.controls['scopeControl'].setValue(this.scopeAdministrationArray[0].value);
          this.infosForm.controls['scopeControl'].enable();
          this.infosForm.controls['numberControl'].enable();

          break;
        case this.companyTypeArray[2].value:
          this.scopeArray = [];
          this.infosForm.controls['scopeControl'].disable();
          this.infosForm.controls['scopeControl'].setValue(null);
          this.infosForm.controls['scopeControl'].setValue(null);
          this.infosForm.controls['numberControl'].enable();

          break;
        case this.companyTypeArray[3].value:
          this.scopeArray = [];
          this.infosForm.controls['scopeControl'].setValue(null);
          this.infosForm.controls['scopeControl'].disable();
          this.disableNumberControl();
          break;
      }
    });

    this.infosForm.controls['scopeControl'].valueChanges.subscribe((value) => {});
  }

  disableNumberControl() {
    this.infosForm.controls['numberControl'].disable();
    this.infosForm.controls['numberControl'].setValue(null);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.infosForm.controls['companyTypeControl'].setValue(this.companyTypeArray[0].value);
  }

  onSubmit() {
    if (this.infosForm.valid) {
      this.onChanges({
        companyType: this.infosForm.controls['companyTypeControl'].value,
        scope: this.infosForm.controls['scopeControl'].value,
        number: this.infosForm.controls['numberControl'].value,
        contactExpertB2b: this.infosForm.controls['contactExpertB2bControl'].value,
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChanges = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {}
}
