import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AdvantageTypeState, AdvantageTypeStore } from './advantage-type.store';

@Injectable({ providedIn: 'root' })
export class AdvantageTypeQuery extends Query<AdvantageTypeState> {
  advantageTypes$ = this.select(state => (state as AdvantageTypeState).list );

  constructor(protected store: AdvantageTypeStore) {
    super(store);
  }

}
