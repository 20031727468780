import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PageSeoApiService } from '@conciliome/b2b-api';
import { PageSeo } from '@conciliome/concilio-model';
import { PageSeoStore } from './page-seo.store';

@Injectable({ providedIn: 'root' })
export class PageSeoService {
  constructor(private pageSeoStore: PageSeoStore, private pageSeoApiService: PageSeoApiService) {}

  find(): Observable<PageSeo[]> {
    return this.pageSeoApiService.find().pipe(
      tap((pagesSeo) => {
        this.pageSeoStore.set(pagesSeo);
      }),
      map((pagesSeo) => pagesSeo.filter((pageSeo) => pageSeo.root && pageSeo.children.length))
    );
  }
}
