import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { SharedStore } from './shared.store';
import { B2bGarantiesApiService, FooterApiService, HeaderApiService, MoreApiService } from '@conciliome/b2b-api';

@Injectable({ providedIn: 'root' })
export class SharedService {
  constructor(
    private sharedStore: SharedStore,
    private moreApiService: MoreApiService,
    private footerApiService: FooterApiService,
    private headerApiService: HeaderApiService,
    private garantiesApiService: B2bGarantiesApiService
  ) {}

  get() {
    return this.getHeader().pipe(
      switchMap(() => this.getFooter()),
      switchMap(() => this.getMoreSection()),
      switchMap(() => this.getGaranties()),
      tap(() => {
        this.sharedStore.setLoaded(true);
      })
    );
  }

  getHeader() {
    return this.headerApiService.get().pipe(
      tap((header) => {
        this.sharedStore.updateHeader(header);
      })
    );
  }

  getFooter() {
    return this.footerApiService.get().pipe(
      tap((footer) => {
        this.sharedStore.updateFooter(footer);
      })
    );
  }

  getMoreSection() {
    return this.moreApiService.get().pipe(
      tap((more) => {
        this.sharedStore.updateMore(more);
      })
    );
  }

  getGaranties() {
    return this.garantiesApiService.get().pipe(
      tap((garanties) => {
        this.sharedStore.updateGaranties(garanties);
      })
    );
  }
}
