import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PageSeoState, PageSeoStore } from './page-seo.store';

@Injectable({ providedIn: 'root' })
export class PageSeoQuery extends QueryEntity<PageSeoState> {
  allPages$ = this.selectAll();
  rootPages$ = this.selectAll({
    filterBy: [({ root }) => !!root, ({ children }) => !!children.length],
  });

  constructor(protected store: PageSeoStore) {
    super(store);
  }
}
