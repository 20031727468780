import { CalendarEvent } from 'angular-calendar';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { B2bFormStore } from './b2b-form.store';
import { ContactPayload } from '@conciliome/concilio-model';

@Injectable({ providedIn: 'root' })
export class B2bFormService {
  constructor(private b2bFormStore: B2bFormStore, private http: HttpClient) {}

  createGeneralInfos(generalInfos: any) {
    this.b2bFormStore.updateGenerals(generalInfos);
  }

  createContact(contact: ContactPayload) {
    this.b2bFormStore.updateContact(contact);
  }

  createDateRDV(date: Date) {
    this.b2bFormStore.updateDate(date);
  }

  createEvent(event: CalendarEvent) {
    this.b2bFormStore.updateEvent(event);
  }
}
