import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Header, Footer, MoreSection, Garanties } from './shared.model';

export interface SharedState {
  header: Header;
  footer: Footer;
  loaded: boolean;
  more_section: MoreSection;
  garanties: Garanties;
}

function createInitialState(): SharedState {
  return {
    header: null,
    footer: null,
    more_section: null,
    loaded: false,
    garanties: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'shared' })
export class SharedStore extends Store<SharedState> {
  constructor() {
    super(createInitialState());
  }

  updateMore(more_section: MoreSection) {
    this.update({ more_section });
  }

  setLoaded(loaded: boolean) {
    this.update({ loaded });
  }

  updateHeader(header: Header) {
    this.update({ header });
  }

  updateFooter(footer: Footer) {
    this.update({ footer });
  }

  updateGaranties(garanties: Garanties) {
    this.update({ garanties });
  }
}
