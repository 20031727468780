import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PageSeo } from '@conciliome/concilio-model';

export interface PageSeoState extends EntityState<PageSeo, number> {}

function createInitialState(): PageSeoState {
  return {} as PageSeoState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'b2b-page-seo' })
export class PageSeoStore extends EntityStore<PageSeoState> {
  constructor() {
    super(createInitialState());
  }
}
