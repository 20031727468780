import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { B2bShellComponent } from './b2b-shell/b2b-shell.component';
import { B2bTunnelShellComponent } from './b2b-tunnel-shell/b2b-tunnel-shell.component';
import { B2bUiDataResolver } from './b2b-ui-data.resolver';

const routes: Routes = [
  {
    path: '',
    component: B2bShellComponent,
    resolve: {
      data: B2bUiDataResolver,
    },
    data: {
      breadcrumbs: 'Accueil',
    },
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        data: { preload: true },
        loadChildren: () =>
          import('./b2b-home/b2b-home.module').then((mod) => mod.B2bHomeModule),
      },
      {
        path: 'services',
        loadChildren: () =>
          import('./b2b-services/b2b-services.module').then(
            (mod) => mod.B2bServicesModule
          ),
      },
      {
        path: 'enjeux-rh',
        loadChildren: () =>
          import('./b2b-testimonials/b2b-testimonials.module').then(
            (mod) => mod.B2bTestimonialsModule
          ),
      },
      // {
      //   path: 'pricing',
      //   loadChildren: () => import('./b2b-pricing/b2b-pricing.module').then((mod) => mod.B2bPricingModule),
      // },
      {
        path: 'livre-blanc',
        loadChildren: () =>
          import('./b2b-livre-blanc/b2b-livre-blanc.module').then(
            (mod) => mod.B2bLivreBlancModule
          ),
      },
      {
        path: 'page',
        loadChildren: () =>
          import('./page-seo/page-seo.module').then((mod) => mod.PageSeoModule),
      },
    ],
  },
  {
    path: 'tunnel',
    component: B2bTunnelShellComponent,
    loadChildren: () =>
      import('@b2b-app/b2b-tunnel').then((mod) => mod.B2bTunnelModule),
  },
  { path: '**', redirectTo: '/home', data: {} },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class B2bUiRoutingModule {}
