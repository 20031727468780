import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TunnelsStore, TunnelsState } from './tunnels.store';

@Injectable({ providedIn: 'root' })
export class TunnelsQuery extends Query<TunnelsState> {
  content$ = this.select((state) => (state as TunnelsState).content);
  step1$ = this.select((state) => (state as TunnelsState).content.step1);

  constructor(protected store: TunnelsStore) {
    super(store);
  }
}
