import { Component, forwardRef, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { TunnelsQuery, TunnelStep1, validEmailPro } from '@b2b-app/b2b-core';
import { ConcilioComponent } from '@conciliome/concilio-core';

@Component({
  selector: 'b2b-tunnel-form',
  templateUrl: './b2b-form.component.html',
  styleUrls: ['./b2b-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => B2bFormComponent),
      multi: true,
    },
  ],
})
export class B2bFormComponent extends ConcilioComponent implements OnInit, ControlValueAccessor {
  @Input() content: TunnelStep1;
  services = ['', 'Direction Générale', 'Ressources Humaines', 'Finance et Comptabilité', 'Office Management', 'Autre'];
  effectifs = ['', '1-5', '6-9', '10-19', '20-50', '51-100', '101-500', '501-1000', '1000+'];
  contactForm: FormGroup = this.fb.group({
    firstnameControl: new FormControl('', [Validators.required]),
    lastnameControl: new FormControl('', [Validators.required]),
    emailControl: new FormControl('', [Validators.required, Validators.email, validEmailPro]),
    telControl: new FormControl('', [
      Validators.required,
      Validators.pattern('([0-9]{10}|(([+][0-9]{2,3})([0-9]{9})))'),
    ]),
    companyControl: new FormControl('', [Validators.required]),
    serviceControl: new FormControl('', [Validators.required]),
    effectifControl: new FormControl('', [Validators.required]),
    contactExpertB2bControl: new FormControl(false),
  });

  tunnelsContent$ = this.tunnelsQuery.content$;

  onChanges: any = (c) => {};
  onTouched: any = () => {};

  constructor(injector: Injector, private fb: FormBuilder, private tunnelsQuery: TunnelsQuery) {
    super(injector);
  }

  ngOnInit(): void {}

  registerOnChange(fn: any): void {
    this.onChanges = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.contactForm.patchValue(obj);
  }

  onSubmit() {
    const contact: any | any = {
      properties: {
        company: this.contactForm.controls['companyControl'].value,
        email: this.contactForm.controls['emailControl'].value,
        firstname: this.contactForm.controls['firstnameControl'].value,
        lastname: this.contactForm.controls['lastnameControl'].value,
        phone: this.contactForm.controls['telControl'].value,
        effectif: this.contactForm.controls['effectifControl'].value,
        jobtitle: this.contactForm.controls['serviceControl'].value,
      },
    };

    if (this.contactForm.valid) {
      // console.log('ON SUBMIT ', contact);
      this.onChanges(contact);
    }
  }
}
