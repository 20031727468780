<b2b-ui-header
  [isFormPage]="hasForm"
  [isSticky]="isHeaderSticky"
  [isStickyOnScroll]="isStickyOnScroll"
></b2b-ui-header>

<router-outlet></router-outlet>

<!-- More -->
<b2b-ui-section-partenaires
  [partenaires]="partenaires$ | async"
></b2b-ui-section-partenaires>

<b2b-ui-footer [isFormPage]="hasForm"></b2b-ui-footer>
