import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SplashScreenService } from '@b2b-app/b2b-core';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'b2b-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'b2b';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private splashScreenService: SplashScreenService
  ) {
    this.titleService.setTitle(`Concilio, la plateforme d'accompagnement santé`);
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        //this.updateDescription(event['description'], event['keywords'], event['title']);
        (window as any).Intercom('update');
        // Once the navigation finished, report the page name to Instana.
        if (typeof ineum !== 'undefined') {
          ineum('page', this.router.url);
        }
        if (typeof (window as any)._hsq !== 'undefined') {
          (window as any)._hsq.push(['setPath', this.router.url]);
          (window as any)._hsq.push(['trackPageView']);
        }
      });
    (window as any).Intercom('boot', {
      app_id: 'nanmdj1x',
    });
  }

  /*updateDescription(desc: string, keywords: string, title: string) {
    this.titleService.setTitle(title);
    this.meta.updateTag({ name: 'description', content: desc })
    this.meta.updateTag({ name: 'og:title', content: title })
    this.meta.updateTag({ name: 'og:description', content: desc })
  }*/
}
