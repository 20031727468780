import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AdvantageType } from './advantage-type.model';

export interface AdvantageTypeState {
  list: AdvantageType[];
}

function createInitialState(): AdvantageTypeState {
  return {
    list: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'advantageTypes' })
export class AdvantageTypeStore extends Store<AdvantageTypeState> {
  constructor() {
    super(createInitialState());
  }

  updateList(list: AdvantageType[]) {
    this.update({ list });
  }
}
