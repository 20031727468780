import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { B2bUiVideoModalModule } from '../b2b-ui-video-modal/b2b-ui-video-modal.module';
import { B2bUiSectionTestimonyVideoComponent } from './b2b-ui-section-testimony-video.component';

@NgModule({
  declarations: [B2bUiSectionTestimonyVideoComponent],
  imports: [CommonModule, FlexLayoutModule, MatButtonModule, MatDialogModule, MatIconModule, B2bUiVideoModalModule],
  exports: [B2bUiSectionTestimonyVideoComponent],
})
export class B2bUiSectionTestimonyVideoModule {}
