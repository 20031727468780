import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { B2bUiSectionDiscoverComponent } from './b2b-ui-section-discover.component';

@NgModule({
  declarations: [B2bUiSectionDiscoverComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [B2bUiSectionDiscoverComponent],
})
export class B2bUiSectionDiscoverModule {}
