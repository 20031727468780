import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UiBreadcrumb } from './ui-breadcrumb.model';
import { UiBreadcrubsUtils } from './ui-breadcrumbs.utils';

export class UiBreadcrumbsResolver implements Resolve<UiBreadcrumb[]> {
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UiBreadcrumb[]> | Promise<UiBreadcrumb[]> | UiBreadcrumb[] {
    const data = route.routeConfig.data;
    const path = this.getFullPath(route);

    let label = typeof data.breadcrumbs === 'string' ? data.breadcrumbs : data.breadcrumbs.label || data.label || path;
    label = UiBreadcrubsUtils.stringFormat(label, route.data);

    const crumbs: UiBreadcrumb[] = [
      {
        label,
        path,
      },
    ];

    return of(crumbs);
  }

  getFullPath(route: ActivatedRouteSnapshot): string {
    return this.fetchFullPath(route.pathFromRoot);
  }

  private fetchFullPath(routes: ActivatedRouteSnapshot[]): string {
    return routes.reduce((path, route) => (path += this.fetchRelativePath(route.url)), '');
  }

  private fetchRelativePath(urlSegments: UrlSegment[]): string {
    return urlSegments.reduce((path, urlSegment) => (path += '/' + urlSegment.path), '');
  }
}
