import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { B2bLivreBlancApiService } from '@conciliome/b2b-api';
import { LivreBlanc, LivreBlancPage } from './livre-blanc.model';
import { LivreBlancStore } from './livre-blanc.store';

@Injectable({ providedIn: 'root' })
export class LivreBlancService {
  constructor(private livreBlancStore: LivreBlancStore, private livreBlancApiService: B2bLivreBlancApiService) {}

  get() {
    return this.getPage().pipe(
      switchMap(() => this.getList()),
      tap(() => {
        this.livreBlancStore.setLoaded(true);
      })
    );
  }

  getPage(): Observable<LivreBlancPage> {
    return this.livreBlancApiService.getPage().pipe(
      tap((page) => {
        this.livreBlancStore.updateContent(page);
      })
    );
  }

  getList(): Observable<LivreBlanc[]> {
    return this.livreBlancApiService.getList().pipe(
      tap((list) => {
        this.livreBlancStore.updateList(list as LivreBlanc[]);
      })
    );
  }
}
