import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { B2bUiSectionTestimonialsComponent } from './b2b-ui-section-testimonials.component';
import { GeneralInfosModule } from '@b2b-app/b2b-tunnel';

@NgModule({
  declarations: [B2bUiSectionTestimonialsComponent],
  imports: [CommonModule, FlexLayoutModule, GeneralInfosModule],
  exports: [B2bUiSectionTestimonialsComponent],
})
export class B2bUiSectionTestimonialsModule {}
