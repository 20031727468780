import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { B2bFormState, B2bFormStore } from './b2b-form.store';

@Injectable({ providedIn: 'root' })
export class B2bFormQuery extends Query<B2bFormState> {
  b2bForm$ = this.select((state) => state as B2bFormState);
  contact$ = this.select((state) => (state as B2bFormState).contact);
  event$ = this.select((state) => (state as B2bFormState).event);
  generalInfos$ = this.select((state) => (state as B2bFormState).generalInfos);

  constructor(protected store: B2bFormStore) {
    super(store);
  }
}
