import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { GeneralInfosComponent } from './general-infos.component';
import { MatOptionModule } from '@angular/material/core';

@NgModule({
  declarations: [GeneralInfosComponent],
  imports: [CommonModule, FlexLayoutModule, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatOptionModule, MatFormFieldModule, MatSelectModule],
  exports: [GeneralInfosComponent]
})
export class GeneralInfosModule {}
