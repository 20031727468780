import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CtaSeo } from '@conciliome/concilio-model';

export interface CtaSeoState extends EntityState<CtaSeo, number> {}

function createInitialState(): CtaSeoState {
  return {} as CtaSeoState;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'b2b-cta-seo' })
export class CtaSeoStore extends EntityStore<CtaSeoState> {
  constructor() {
    super(createInitialState());
  }
}
