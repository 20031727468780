import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { CtaSeo } from '@conciliome/concilio-model';
import { CtaSeoState, CtaSeoStore } from './ctas-seo.store';

@Injectable({ providedIn: 'root' })
export class CtaSeoQuery extends QueryEntity<CtaSeoState> {
  allCtas$: Observable<CtaSeo[]> = this.selectAll();
  ctaSeo$: Observable<CtaSeo> = this.selectAll({
    filterBy: ({ slug }) => {
      console.log(slug);
      return slug === 'cta-seo';
    },
  })[0];

  constructor(protected store: CtaSeoStore) {
    super(store);
  }
}
