import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { PageSeoQuery, SharedQuery } from '@b2b-app/b2b-core';
import { ConcilioComponent } from '@conciliome/concilio-core';

@Component({
  selector: 'b2b-ui-footer',
  templateUrl: './b2b-ui-footer.component.html',
  styleUrls: ['./b2b-ui-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class B2bUiFooterComponent extends ConcilioComponent implements OnInit, OnChanges {
  @Input() isFormPage = false;
  footer$ = this.sharedQuery.footer$;
  pages$ = this.pageSeoQuery.rootPages$;

  constructor(
    injector: Injector,
    private cd: ChangeDetectorRef,
    private sharedQuery: SharedQuery,
    private pageSeoQuery: PageSeoQuery
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isFormPage && changes.isFormPage.firstChange) {
      this.cd.detectChanges();
    }
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }
}
