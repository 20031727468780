import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { B2bUiSectionPartenairesComponent } from './b2b-ui-section-partenaires.component';
import { UiCarouselModule } from '../ui-carousel/ui-carousel.module';

@NgModule({
  declarations: [B2bUiSectionPartenairesComponent],
  imports: [CommonModule, FlexLayoutModule, UiCarouselModule],
  exports: [B2bUiSectionPartenairesComponent],
})
export class B2bUiSectionPartenairesModule {}
