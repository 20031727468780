import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  Input,
  OnInit,
  Renderer2,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, share, throttleTime } from 'rxjs/operators';
import { ConcilioComponent } from '@conciliome/concilio-core';
import { SharedQuery } from '@b2b-app/b2b-core';

@Component({
  selector: 'b2b-ui-header',
  templateUrl: './b2b-ui-header.component.html',
  styleUrls: ['./b2b-ui-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class B2bUiHeaderComponent extends ConcilioComponent implements OnInit, AfterViewInit {
  @Input() isSticky = false;
  @Input() isStickyOnScroll = true;
  @Input() isFormPage = false;

  isMenuOpened = false;
  header$ = this.sharedQuery.header$;

  constructor(
    injector: Injector,
    private router: Router,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    private sharedQuery: SharedQuery,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addSubscription(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        this.isMenuOpened = false;
      })
    );
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.stickyHeaderOnScroll();
  }

  toggleMenu() {
    this.isMenuOpened = !this.isMenuOpened;
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  private stickyHeaderOnScroll() {
    const topNav = this.document.querySelector('#topnav');
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      distinctUntilChanged(),
      share()
    );

    const scrollUp$ = scroll$.pipe(filter((offset) => offset === 0));

    const scrollDown$ = scroll$.pipe(filter((offset) => offset > 74));

    this.addSubscription(
      scrollDown$.subscribe(() => {
        if (this.isStickyOnScroll) {
          this.renderer.addClass(topNav, 'nav-sticky');
          this.cd.markForCheck();
        }
      })
    );
    this.addSubscription(
      scrollUp$.subscribe(() => {
        if (this.isStickyOnScroll) {
          this.renderer.removeClass(topNav, 'nav-sticky');
          this.cd.markForCheck();
        }
      })
    );
  }
}
