import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CtaApiService } from '@conciliome/b2b-api';
import { CtaSeo } from '@conciliome/concilio-model';
import { CtaSeoStore } from './ctas-seo.store';

@Injectable({ providedIn: 'root' })
export class CtaSeoService {
  constructor(private ctaSeoStore: CtaSeoStore, private ctaSeoApiService: CtaApiService) {}

  getCtaSeo(): Observable<CtaSeo> {
    return this.ctaSeoApiService.get().pipe(
      tap((ctasSeo) => {
        this.ctaSeoStore.set(ctasSeo);
      }),
      map((ctasSeo) => ctasSeo.find((ctaSeo) => ctaSeo.slug === 'cta-seo'))
    );
  }
}
