import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { B2bUiSectionSimulatorComponent } from './b2b-ui-section-simulator.component';
import { B2bTunnelModule, GeneralInfosModule } from '@b2b-app/b2b-tunnel';

@NgModule({
  declarations: [B2bUiSectionSimulatorComponent],
  imports: [CommonModule, FlexLayoutModule, ReactiveFormsModule, B2bTunnelModule , GeneralInfosModule],
  exports: [B2bUiSectionSimulatorComponent],
})
export class B2bUiSectionSimulatorModule {}
