import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { PricingPage } from './pricing.model';

export interface PricingState {
  content: PricingPage;
}

function createInitialState(): PricingState {
  return {
    content: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'pricing' })
export class PricingStore extends Store<PricingState> {
  constructor() {
    super(createInitialState());
  }
  updateContent(content: PricingPage) {
    this.update({ content });
  }
}
