import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CookieService } from 'ngx-cookie-service';
import { B2bTunnelRoutingModule } from './b2b-tunnel-routing.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    B2bTunnelRoutingModule,
  ],
  exports: [],
  declarations: [],
  providers: [CookieService],
})
export class B2bTunnelModule {}
