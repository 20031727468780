import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { B2bUiBreadcrumbComponent } from './b2b-ui-breadcrumb.component';

@NgModule({
  declarations: [B2bUiBreadcrumbComponent],
  imports: [CommonModule, MatIconModule],
  exports: [B2bUiBreadcrumbComponent],
})
export class B2bUiBreadcrumbModule {}
