import { DOCUMENT } from '@angular/common';
import { Component, Injector, OnInit, AfterViewInit, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcStatusChangeEvent,
  NgcNoCookieLawEvent,
} from 'ngx-cookieconsent';
import { filter, tap } from 'rxjs/operators';
import { ConcilioComponent } from '@conciliome/concilio-core';
import { Observable } from 'rxjs';

@Component({
  selector: 'b2b-ui-shell',
  templateUrl: './b2b-shell.component.html',
  styleUrls: ['./b2b-shell.component.scss'],
})
export class B2bShellComponent extends ConcilioComponent implements OnInit, AfterViewInit {
  hasForm = false;
  isHeaderSticky = false;
  isStickyOnScroll = false;
  consents$: Observable<string[]>;

  constructor(
    injector: Injector,
    private router: Router,
    @Inject(DOCUMENT) private dom: Document,
    private cookieService: CookieService,
    private ccService: NgcCookieConsentService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.setHeaderSticky(this.router.url);
    this.setHasForm(this.router.url);
    this.updateCanonicalUrl();

    this.addSubscription(
      this.router.events.subscribe((routerEvent) => {
        if (routerEvent instanceof NavigationEnd) {
          this.setHeaderSticky(routerEvent.urlAfterRedirects);
          this.setHasForm(routerEvent.urlAfterRedirects);
          this.updateCanonicalUrl();
        }
      })
    );

    this.addSubscription(
      this.route.queryParams
        .pipe(
          tap((queryParams) => {
            for (const key in queryParams) {
              if (Object.prototype.hasOwnProperty.call(queryParams, key)) {
                this.cookieService.set(key, queryParams[key]);
              }
            }
          })
        )
        .subscribe()
    );

    this.addSubscription(
      this.ccService.popupOpen$.subscribe(() => {
        console.log('popupOpen');
      })
    );
    this.addSubscription(
      this.ccService.popupClose$.subscribe(() => {
        console.log('popupClose');
      })
    );
    this.addSubscription(
      this.ccService.initialize$.subscribe((event) => {
        console.log(`initialize ${JSON.stringify(event)}`);
      })
    );
    this.addSubscription(
      this.ccService.revokeChoice$.subscribe(() => {
        console.log('revokeChoice');
      })
    );
    this.addSubscription(
      this.ccService.noCookieLaw$.subscribe((event) => {
        console.log(`noCookieLaw ${JSON.stringify(event)}`);
      })
    );
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.addSubscription(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((evt: NavigationEnd) => {
        const fragment = evt.urlAfterRedirects.split('#')[1];
        setTimeout(() => {
          this.scrollToId(fragment);
        }, 150);
      })
    );

    this.addSubscription(
      this.route.fragment.subscribe((fragment) => {
        if (fragment) {
          setTimeout(() => {
            this.scrollToId(fragment);
          }, 150);
        }
      })
    );

    const links = document.links;
    for (let i = 0, linksLength = links.length; i < linksLength; i++) {
      if (links[i].hostname !== window.location.hostname) {
        links[i].setAttribute('target', '_blank');
      }
    }
  }

  updateCanonicalUrl() {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', `${location.origin}${location.pathname}`);
  }

  private scrollToId(fragment: string) {
    const element = document.querySelector(`#${fragment}`) as HTMLElement;
    const top = element?.getBoundingClientRect()?.top - 74;
    window.scroll({ top, behavior: 'smooth' });
  }

  private setHeaderSticky(url: string) {
    switch (url) {
      case '/home':
        this.isHeaderSticky = false;
        this.isStickyOnScroll = true;
        break;
      default:
        this.isHeaderSticky = true;
        this.isStickyOnScroll = false;
        break;
    }
  }

  private setHasForm(url: string) {
    if (url.startsWith('/tunnels')) {
      this.hasForm = true;
    } else {
      this.hasForm = false;
    }
  }
}
