import {
  Component,
  Injector,
  Input,
  OnInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ConcilioComponent } from '@conciliome/concilio-core';
import { UiCarouselComponent } from '../ui-carousel/ui-carousel.component';

@Component({
  selector: 'b2b-ui-section-partenaires',
  templateUrl: './b2b-ui-section-partenaires.component.html',
  styleUrls: ['./b2b-ui-section-partenaires.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class B2bUiSectionPartenairesComponent extends ConcilioComponent implements OnInit, OnChanges {
  @ViewChild('myCarousel', { static: false }) myCarousel: UiCarouselComponent;
  @Input() partenaires: any;
  update = false;

  constructor(injector: Injector, private cd: ChangeDetectorRef) {
    super(injector);
    this.cd.detach();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.partenaires && changes.partenaires.currentValue) {
      this.update = true;
      this.cd.detectChanges();
    }
  }

  prev() {
    this.myCarousel.prev();
    this.cd.detectChanges();
  }

  next() {
    this.myCarousel.next();
    this.cd.detectChanges();
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }
}
