import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CustomPreloadingStrategyService } from '@b2b-app/b2b-core';

const routes: Routes = [
  {
    path: '',
    data: { preload: true },
    loadChildren: () => import('@b2b-app/b2b-ui').then((mod) => mod.B2bUiModule),
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
      initialNavigation: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 74],
      relativeLinkResolution: 'legacy',
      preloadingStrategy: CustomPreloadingStrategyService,
    }),
    CommonModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
