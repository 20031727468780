import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { LivreBlanc, LivreBlancPage } from './livre-blanc.model';

export interface LivreBlancState {
  content: LivreBlancPage;
  livresBlancList: LivreBlanc[];
  loaded: boolean;
}

function createInitialState(): LivreBlancState {
  return {
    content: null,
    livresBlancList: null,
    loaded: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'livre-blanc' })
export class LivreBlancStore extends Store<LivreBlancState> {
  constructor() {
    super(createInitialState());
  }

  setLoaded(loaded: boolean) {
    this.update({ loaded });
  }

  updateContent(content: LivreBlancPage) {
    this.update({ content });
  }

  updateList(livresBlancList: LivreBlanc[]) {
    this.update({ livresBlancList });
  }
}
