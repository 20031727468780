<form class="b2b-form contact-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1em">
    <!-- Prenom & Nom -->
    <div
      class="form-group-wrapper"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxLayoutGap="1em"
    >
      <div class="form-group">
        <div class="label">{{ content?.firstnameText || 'Prénom' }}<sup>*</sup> :</div>
        <mat-form-field appearance="outline">
          <input matInput placeholder="" [formControl]="contactForm.controls['firstnameControl']" />
          <mat-error
            *ngIf="
              contactForm.get('firstnameControl').touched && contactForm.get('firstnameControl').hasError('required')
            "
            >Veuillez remplir ce champ obligatoire</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-group">
        <div class="label">{{ content?.lastnameText || 'Nom' }}<sup>*</sup> :</div>
        <mat-form-field appearance="outline">
          <input matInput placeholder="" [formControl]="contactForm.controls['lastnameControl']" />
          <mat-error
            *ngIf="
              contactForm.get('lastnameControl').touched && contactForm.get('lastnameControl').hasError('required')
            "
            >Veuillez remplir ce champ obligatoire</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <!-- E-mail & Telephone -->
    <div
      class="form-group-wrapper"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxLayoutGap="1em"
    >
      <div class="form-group">
        <div class="label">{{ content?.emailText || 'E-mail professionnel' }}<sup>*</sup> :</div>
        <mat-form-field appearance="outline">
          <input matInput placeholder="" [formControl]="contactForm.controls['emailControl']" />
          <mat-error
            *ngIf="contactForm.get('emailControl').touched && contactForm.get('emailControl').hasError('required')"
            >Veuillez remplir ce champ obligatoire</mat-error
          >
          <mat-error
            *ngIf="
              !contactForm.get('emailControl').hasError('required') &&
              contactForm.get('emailControl').hasError('validEmailPro')
            "
            >Merci de renseigner un email professionnel</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-group">
        <div class="label">{{ content?.phoneNumberText || 'Numéro de téléphone' }}<sup>*</sup> :</div>
        <mat-form-field appearance="outline">
          <input matInput placeholder="" [formControl]="contactForm.controls['telControl']" />
          <mat-error *ngIf="contactForm.get('telControl').touched && contactForm.get('telControl').hasError('required')"
            >Veuillez remplir ce champ obligatoire</mat-error
          >
          <mat-error
            *ngIf="
              !contactForm.get('telControl').hasError('required') && contactForm.get('telControl').hasError('pattern')
            "
            >Numéro non valide</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <!-- Entreprise -->
    <div
      class="form-group-wrapper"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxLayoutGap="1em"
    >
      <div class="form-group">
        <div class="label">{{ content?.companyNameText || "Nom de l'entreprise" }}<sup>*</sup> :</div>
        <mat-form-field appearance="outline" class="w-100">
          <input matInput placeholder="" [formControl]="contactForm.controls['companyControl']" />
          <mat-error
            *ngIf="contactForm.get('companyControl').touched && contactForm.get('companyControl').hasError('required')"
            >Veuillez remplir ce champ obligatoire</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <!-- Service & Effectif -->
    <div
      class="form-group-wrapper"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between start"
      fxLayoutGap="1em"
    >
      <div class="form-group">
        <div class="label">{{ content?.serviceText || 'Service' }}<sup>*</sup> :</div>
        <mat-form-field appearance="outline">
          <mat-select [formControl]="contactForm.controls['serviceControl']">
            <mat-option *ngFor="let service of services" [value]="service">{{ service }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="contactForm.get('serviceControl').touched && contactForm.get('serviceControl').hasError('required')"
            >Veuillez remplir ce champ obligatoire</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-group">
        <div class="label">{{ content?.effectifText || 'Effectif de votre entreprise' }}<sup>*</sup> :</div>
        <mat-form-field appearance="outline">
          <mat-select [formControl]="contactForm.controls['effectifControl']">
            <mat-option *ngFor="let effectif of effectifs" [value]="effectif">{{ effectif }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              contactForm.get('effectifControl').touched && contactForm.get('effectifControl').hasError('required')
            "
            >Veuillez remplir ce champ obligatoire</mat-error
          >
        </mat-form-field>
      </div>
    </div>

    <div class="button" fxLayout="row" fxLayoutAlign="center center">
      <button id="submit-contact-form" class="btn btn-rounded btn-action" [disabled]="!contactForm.valid" type="submit">
        <span>{{ content?.ctaText || 'Valider' }}</span>
      </button>
    </div>
  </div>
</form>
