import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { B2bUiHeaderModule } from '../b2b-ui-header/b2b-ui-header.module';
import { B2bUiFooterModule } from '../b2b-ui-footer/b2b-ui-footer.module';
import { B2bShellComponent } from './b2b-shell.component';
import { UiBreadcrumbsModule } from '../ui-breadcrumbs/ui-breadcrumbs.module';

@NgModule({
  declarations: [B2bShellComponent],
  imports: [CommonModule, FlexLayoutModule, RouterModule, UiBreadcrumbsModule, B2bUiHeaderModule, B2bUiFooterModule],
  exports: [B2bShellComponent],
})
export class B2bShellModule {}
