import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { filter, mergeMap, first, distinct, tap, toArray } from 'rxjs/operators';
import { UiBreadcrumbsConfig } from './ui-breadcrumbs.config';
import { UiBreadcrumb } from './ui-breadcrumb.model';
import { UiBreadcrumbsResolver } from './ui-breadcrumbs.resolver';
import { UiBreadcrubsUtils } from './ui-breadcrumbs.utils';

@Injectable()
export class UiBreadcrumbsService {
  private breadcrumbs = new BehaviorSubject<UiBreadcrumb[]>([]);
  private defaultResolver = new UiBreadcrumbsResolver();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private config: UiBreadcrumbsConfig
  ) {
    this.router.events.pipe(filter((x) => x instanceof NavigationEnd)).subscribe((_: NavigationEnd) => {
      const routeRoot = router.routerState.snapshot.root;

      this.resolveCrumbs(routeRoot)
        .pipe(
          mergeMap((crumbs: UiBreadcrumb[]) => crumbs),
          this.config.applyDistinctOn ? distinct((crumb: UiBreadcrumb) => crumb[this.config.applyDistinctOn]) : tap(),
          toArray(),
          mergeMap((crumbs: UiBreadcrumb[]) => {
            if (this.config.postProcess) {
              const postProcessedCrumb = this.config.postProcess(crumbs);
              return UiBreadcrubsUtils.wrapIntoObservable<UiBreadcrumb[]>(postProcessedCrumb).pipe(first());
            } else {
              return of(crumbs);
            }
          })
        )
        .subscribe((crumbs: UiBreadcrumb[]) => {
          this.breadcrumbs.next(crumbs);
        });
    });
  }

  get crumbs$(): Observable<UiBreadcrumb[]> {
    return this.breadcrumbs;
  }

  getCrumbs(): Observable<UiBreadcrumb[]> {
    return this.crumbs$;
  }

  private resolveCrumbs(route: ActivatedRouteSnapshot): Observable<UiBreadcrumb[]> {
    let crumbs$: Observable<UiBreadcrumb[]>;
    const data = route.routeConfig && route.routeConfig.data;

    if (data && data.breadcrumbs) {
      let resolver: UiBreadcrumbsResolver;

      if (data.breadcrumbs.prototype instanceof UiBreadcrumbsResolver) {
        resolver = this.injector.get(data.breadcrumbs);
      } else {
        resolver = this.defaultResolver;
      }

      const result = resolver.resolve(route, this.router.routerState.snapshot);
      crumbs$ = UiBreadcrubsUtils.wrapIntoObservable<UiBreadcrumb[]>(result).pipe(first());
    } else {
      crumbs$ = of([]);
    }

    if (route.firstChild) {
      crumbs$ = concat(crumbs$, this.resolveCrumbs(route.firstChild));
    }

    return crumbs$;
  }
}
