import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DownloadFormStore } from './download-form.store';
import { ContactPayload } from '@conciliome/concilio-model';

@Injectable({ providedIn: 'root' })
export class DownloadFormService {
  constructor(private downloadFormStore: DownloadFormStore, private http: HttpClient) {}

  createContact(contact: ContactPayload) {
    this.downloadFormStore.updateContact(contact);
  }
}
