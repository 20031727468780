import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { B2bUiSectionPricingComponent } from './b2b-ui-section-pricing.component';

@NgModule({
  declarations: [B2bUiSectionPricingComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [B2bUiSectionPricingComponent],
})
export class B2bUiSectionPricingModule {}
