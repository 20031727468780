import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AdvantageTypeStore } from './advantage-type.store';
import { AdvantageTypeApiService } from '@conciliome/b2b-api';
import { AdvantageType } from './advantage-type.model';

@Injectable({ providedIn: 'root' })
export class AdvantageTypeService {
  constructor(
    private advantageTypeStore: AdvantageTypeStore,
    private advantageTypeApiService: AdvantageTypeApiService
  ) {}

  getList(): Observable<AdvantageType[]> {
    return this.advantageTypeApiService.getList().pipe(
      tap((list: AdvantageType[]) => {
        this.advantageTypeStore.updateList(list);
      })
    );
  }
}
