import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ContactPayload } from '@conciliome/concilio-model';

export interface EssaiFormState {
  generalInfos: any;
  contact: ContactPayload;
}

function createInitialState(): EssaiFormState {
  return {
    generalInfos: null,
    contact: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'essai-form' })
export class EssaiFormStore extends Store<EssaiFormState> {
  constructor() {
    super(createInitialState());
  }

  updateContact(contact: ContactPayload) {
    this.update({ contact });
  }

  updateGenerals(generalInfos: any) {
    this.update({ generalInfos });
  }
}
