import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { UiBreadcrumbsComponent } from './ui-breadcrumbs.component';
import { UiBreadcrumbsConfig } from './ui-breadcrumbs.config';
import { UiBreadcrumbsService } from './ui-breadcrumbs.service';

@NgModule({
  declarations: [UiBreadcrumbsComponent],
  imports: [CommonModule, RouterModule, MatIconModule],
  exports: [UiBreadcrumbsComponent],
})
export class UiBreadcrumbsModule {
  static forRoot(config: UiBreadcrumbsConfig): ModuleWithProviders<UiBreadcrumbsModule> {
    return {
      ngModule: UiBreadcrumbsModule,
      providers: [
        { provide: UiBreadcrumbsConfig, useFactory: () => new UiBreadcrumbsConfig(config) },
        UiBreadcrumbsService,
      ],
    };
  }
}
