import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { HomepageState, HomepageStore } from './homepage.store';

@Injectable({ providedIn: 'root' })
export class HomepageQuery extends Query<HomepageState> {
  content$ = this.select((state) => (state as HomepageState).content);
  partenaires$ = this.select((state) => state.content?.partenaires);

  constructor(protected store: HomepageStore) {
    super(store);
  }
}
