import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HomepageStore } from './homepage.store';
import { HomepageApiService } from '@conciliome/b2b-api';

@Injectable({ providedIn: 'root' })
export class HomepageService {
  constructor(private homepageStore: HomepageStore, private apiService: HomepageApiService) {}

  get() {
    return this.apiService.get().pipe(
      tap((content: any) => {
        this.homepageStore.updateContent(content);
      })
    );
  }
}
