import { AbstractControl, ValidatorFn } from '@angular/forms';

const blacklistedDomains = ['gmail', 'hotmail', 'yahoo', 'ymail', 'yopmail', 'live'];

export const validEmailPro: ValidatorFn = (control: AbstractControl) => {
  if (!/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(control.value)) {
    return {
      validEmailPro: {
        reason: 'invalid format',
        value: control.value,
      },
    };
  }

  const domain = control.value.split('@').pop().split('.')[0];

  if (blacklistedDomains.includes(domain)) {
    return {
      validEmailPro: {
        reason: 'blacklisted',
        value: control.value,
      },
    };
  }

  // Is valid
  return null;
};
