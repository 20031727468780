import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { B2bServicesStore } from './b2b-services.store';
import { B2bServicesApiService } from '@conciliome/b2b-api';

@Injectable({ providedIn: 'root' })
export class B2bServicesService {
  constructor(private b2bServicesStore: B2bServicesStore, private b2bServicesApiService: B2bServicesApiService) {}

  get() {
    return this.b2bServicesApiService.get().pipe(
      tap((content) => {
        this.b2bServicesStore.updateContent(content);
      })
    );
  }
}
