import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { B2bUiHeaderModule } from '../b2b-ui-header/b2b-ui-header.module';
import { B2bUiFooterModule } from '../b2b-ui-footer/b2b-ui-footer.module';
import { B2bUiSectionPartenairesModule } from '../b2b-ui-section-partenaires/b2b-ui-section-partenaires.module';
import { B2bTunnelShellComponent } from './b2b-tunnel-shell.component';

@NgModule({
  declarations: [B2bTunnelShellComponent],
  imports: [CommonModule, RouterModule, B2bUiHeaderModule, B2bUiFooterModule, B2bUiSectionPartenairesModule],
  exports: [B2bTunnelShellComponent],
})
export class B2bTunnelShellModule {}
