import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CalendarEvent } from 'angular-calendar';
import { ContactPayload } from '@conciliome/concilio-model';

export interface B2bFormState {
  generalInfos: any;
  contact: ContactPayload;
  date: Date;
  event: CalendarEvent;
}

function createInitialState(): B2bFormState {
  return {
    generalInfos: null,
    contact: null,
    date: null,
    event: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'b2b-form' })
export class B2bFormStore extends Store<B2bFormState> {
  constructor() {
    super(createInitialState());
  }

  updateGenerals(generalInfos: any) {
    console.log('UPDATE GENERALS', generalInfos);
    this.update({ generalInfos });
  }

  updateContact(contact: ContactPayload) {
    this.update({ contact });
  }

  updateDate(date: Date) {
    this.update({ date });
  }

  updateEvent(event: CalendarEvent) {
    this.update({ event });
  }
}
