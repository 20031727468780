import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ContactPayload } from '@conciliome/concilio-model';

export interface DownloadFormState {
   contact: ContactPayload;
}

 function createInitialState(): DownloadFormState {
  return {
    contact: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'download-form' })
export class DownloadFormStore extends Store<DownloadFormState> {

  constructor() {
    super(createInitialState());
  }

  updateContact(contact: ContactPayload) {
    this.update({ contact});
  }
}
