import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { B2bTunnelsApiService } from '@conciliome/b2b-api';
import { TunnelsStore } from './tunnels.store';
import { Tunnel } from './tunnels.model';

@Injectable({ providedIn: 'root' })
export class TunnelsService {
  constructor(private tunnelsStore: TunnelsStore, private tunnelsApiService: B2bTunnelsApiService) {}

  get() {
    return this.tunnelsApiService.get().pipe(
      tap((content: Tunnel) => {
        this.tunnelsStore.updateContent(content);
      })
    );
  }
}
