import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { EnjeuxRHPage, Temoignage } from '@b2b-app/b2b-core';

export interface TemoignageState {
  content: EnjeuxRHPage;
  selectedTemoignage: Temoignage;
}

function createInitialState(): TemoignageState {
  return {
    content: null,
    selectedTemoignage: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'temoignage' })
export class TemoignageStore extends Store<TemoignageState> {
  constructor() {
    super(createInitialState());
  }

  updateContent(content: EnjeuxRHPage) {
    this.update({ content });
  }

  selectTemoignage(temoignage: Temoignage) {
    this.update({ selectedTemoignage: temoignage });
  }
}
