<div class="general-info w-100" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2em">
  <!-- <div>RENSEIGNEZ LES INFORMATIONS CI-DESSOUS</div> -->
  <form class="form w-100" [formGroup]="infosForm" fxLayout="column" fxLayoutAlign="center center">
    <div
      class="form-group"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-around start"
      fxLayoutAlign.lt-md="start center"
      fxLayoutGap="50px"
      fxLayoutGap.lt-md="8px"
    >
      <div fxFlex="50" fxFlexAlign="center">
        <div class="label">
          <span>Vous êtes<sup>*</sup> :</span>
        </div>
      </div>

      <div fxFlex="grow">
        <mat-form-field appearance="outline">
          <mat-select [formControl]="infosForm.controls['companyTypeControl']" required>
            <mat-option *ngFor="let companyType of companyTypeArray" [value]="companyType.value">
              {{ companyType.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div
      class="form-group"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-around start"
      fxLayoutAlign.lt-md="start center"
      fxLayoutGap="50px"
      fxLayoutGap.lt-md="8px"
    >
      <div fxFlex="50" fxFlexAlign="center">
        <div class="label"><span>Vous souhaitez couvrir :</span></div>
      </div>

      <div fxFlex="grow">
        <mat-form-field appearance="outline">
          <mat-select [formControl]="infosForm.controls['scopeControl']">
            <mat-option *ngFor="let scope of scopeArray" [value]="scope.value">
              {{ scope.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div
      class="form-group"
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-around start"
      fxLayoutAlign.lt-md="start center"
      fxLayoutGap="50px"
      fxLayoutGap.lt-md="8px"
    >
      <div fxFlex="50" fxFlexAlign="center">
        <div class="label">
          <span>Cela représente<br />combien de personnes ?</span>
        </div>
      </div>

      <div fxFlex="grow">
        <mat-form-field appearance="outline">
          <input matInput type="number" [formControl]="infosForm.controls['numberControl']" />
        </mat-form-field>
      </div>
    </div>

    <!-- <ng-container *ngIf="type === 'essai'">
      <div
        class="form-group"
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-around end"
        fxLayoutAlign.lt-md="start center"
      >
        <div fxLayoutAlign="end" fxLayoutAlign.lt-md="start">
          <mat-checkbox [formControl]="infosForm.controls['contactExpertB2bControl']">
            <span>Je souhaite être contacté par un Expert Santé B to B</span>
          </mat-checkbox>
        </div>
      </div>
    </ng-container> -->

    <button class="btn btn-rounded btn-action" (click)="onSubmit()">
      {{ ctaText }}
    </button>
  </form>
</div>
