import { from, Observable, of } from 'rxjs';

export abstract class UiBreadcrubsUtils {
  static stringFormat(rawTemplate: string, data: any): string {
    const templateRegex = new RegExp('{{[\\s]*[a-zA-Z._]+?[\\s]*}}', 'g');

    return rawTemplate.replace(templateRegex, (match) => {
      const keyRegex = new RegExp('([a-zA-Z._]+)', 'g');
      const key = match.match(keyRegex);

      if (!key || !key.length) {
        return match;
      }

      const value = UiBreadcrubsUtils.leaf(data, key[0]);

      if (!value) {
        return key[0];
      }

      return value;
    });
  }

  static wrapIntoObservable<T>(value: T | Promise<T> | Observable<T>): Observable<T> {
    if (value instanceof Observable) {
      return value;
    }

    if (this.isPromise(value)) {
      return from(Promise.resolve(value));
    }

    return of(value as T);
  }

  static isPromise(value: any): boolean {
    return value && typeof value.then === 'function';
  }

  static leaf(obj: any, path: string) {
    const result = path.split('.').reduce((value, el) => value[el] || {}, obj);

    return UiBreadcrubsUtils.isEmptyObject(result) ? null : result;
  }

  static isEmptyObject(obj): boolean {
    if (typeof obj === 'object' && Object.prototype.toString.call(obj) === '[object Object]') {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          return false;
        }
      }

      return true;
    }

    return false;
  }
}
