import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ServicePage } from './b2b-services.model';

export interface B2bServicesState {
  content: ServicePage;
}

function createInitialState(): B2bServicesState {
  return {
    content: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'b2b-services' })
export class B2bServicesStore extends Store<B2bServicesState> {
  constructor() {
    super(createInitialState());
  }

  updateContent(content: ServicePage) {
    this.update({ content });
  }
}
