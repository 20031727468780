import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TemoignageState, TemoignageStore } from './temoignage.store';

@Injectable({ providedIn: 'root' })
export class TemoignageQuery extends Query<TemoignageState> {
  content$ = this.select((state) => (state as TemoignageState).content);
  temoignages$ = this.select((state) => (state as TemoignageState).content?.temoignage?.temoignages);
  selectedTemoignage$ = this.select((state) => (state as TemoignageState).selectedTemoignage);

  constructor(protected store: TemoignageStore) {
    super(store);
  }
}
