import { CarouselComponent } from 'angular-responsive-carousel';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'concilio-front-ui-carousel',
  templateUrl: './ui-carousel.component.html',
  styleUrls: ['./ui-carousel.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  exportAs: 'uiCarousel',
})
export class UiCarouselComponent extends CarouselComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() update = false;

  constructor(elementRef: ElementRef, changeDetectorRef: ChangeDetectorRef) {
    super(elementRef, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.update && changes.update.firstChange) {
      setTimeout(() => {
        this.onDomChanges();
      });
    }
  }

  goToSlide(index: number) {
    if (index > this.activeDotIndex) {
      this.carousel.next(index - this.activeDotIndex);
    } else {
      this.carousel.prev(this.activeDotIndex - index);
    }
  }
}
