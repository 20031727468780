<footer class="footer" *ngIf="footer$ | async as footer">
  <div class="container-xl">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
      <div fxFlex="25" fxLayout="column" fxLayoutAlign="start start">
        <a class="logo" [href]="footer?.logoLink">
          <img class="logo-image" [src]="footer?.logo?.url" [alt]="footer?.logo?.alternativeText" />
        </a>
        <div class="footer-list mt-4" fxLayout="column" *ngIf="!isFormPage">
          <ng-container *ngFor="let page of pages$ | async; trackBy: trackByFn">
            <a class="footer-text" [href]="'/page/' + page?.slug">{{ page?.titre }}</a>
          </ng-container>
        </div>
      </div>
      <ng-container *ngFor="let column of footer?.columns; trackBy: trackByFn">
        <div [fxFlex]="column.size" [fxHide]="isFormPage && !column.showOnTunnels">
          <h4 class="footer-title">{{ column.title }}</h4>
          <div class="footer-list mt-5" fxLayout="column">
            <ng-container *ngFor="let link of column.links; trackBy: trackByFn">
              <a
                class="footer-text"
                [href]="[link.url]"
                [target]="link.targetBlank ? '_blank' : ''"
                [rel]="link.targetBlank ? 'noopener' : ''"
                >{{ link.text }}</a
              >
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</footer>
