import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { B2bUiHeaderComponent } from './b2b-ui-header.component';

@NgModule({
  declarations: [B2bUiHeaderComponent],
  imports: [CommonModule, FlexLayoutModule, RouterModule],
  exports: [B2bUiHeaderComponent],
})
export class B2bUiHeaderModule {}
