import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { B2bUiSectionMoreComponent } from './b2b-ui-section-more.component';

@NgModule({
  declarations: [B2bUiSectionMoreComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [B2bUiSectionMoreComponent],
})
export class B2bUiSectionMoreModule {}
