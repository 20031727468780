<section class="section partenaires" *ngIf="partenaires">
  <div class="container-xl">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div class="section-title text-center pb-2">
        <h2 class="title">{{ partenaires?.title }}</h2>
        <h3 class="subtitle">{{ partenaires?.subtitle }}</h3>
      </div>
      <div class="section-content">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <!-- desktop -->
          <concilio-front-ui-carousel
            #myCarousel="uiCarousel"
            [height]="50"
            [width]="960"
            [margin]="10"
            [cellsToShow]="5"
            [loop]="true"
            [autoplay]="true"
            [arrowsOutside]="true"
            [update]="update"
            fxHide.lt-md
          >
            <ng-container *ngFor="let item of partenaires?.companies; trackBy: trackByFn">
              <div class="carousel-cell" [ngClass]="item.classes">
                <img [src]="item.logo?.url" [alt]="item.logo?.alternativeText" [title]="item.title" loading="lazy" />
              </div>
            </ng-container>
          </concilio-front-ui-carousel>
          <!-- tablet -->
          <concilio-front-ui-carousel
            #myCarousel="uiCarousel"
            [height]="50"
            [width]="560"
            [margin]="10"
            [cellsToShow]="3"
            [loop]="true"
            [autoplay]="true"
            [arrowsOutside]="true"
            [update]="update"
            fxHide
            fxHide.lt-sm
            fxShow.lt-md
          >
            <ng-container *ngFor="let item of partenaires?.companies; trackBy: trackByFn">
              <div class="carousel-cell" [ngClass]="item.classes">
                <img [src]="item.logo?.url" [alt]="item.logo?.alternativeText" [title]="item.title" loading="lazy" />
              </div>
            </ng-container>
          </concilio-front-ui-carousel>
          <!-- Mobile -->
          <concilio-front-ui-carousel
            #myCarousel="uiCarousel"
            [width]="240"
            [cellsToShow]="1"
            [cellWidth]="'100%'"
            [autoplay]="true"
            [loop]="true"
            [dots]="true"
            [arrows]="false"
            [update]="update"
            fxHide
            fxShow.lt-sm
          >
            <ng-container *ngFor="let item of partenaires?.companies; trackBy: trackByFn">
              <div class="carousel-cell">
                <img
                  [src]="item.logo?.formats?.small?.url"
                  [alt]="item.logo?.alternativeText"
                  [title]="item.title"
                  loading="lazy"
                />
              </div>
            </ng-container>
          </concilio-front-ui-carousel>
        </div>
      </div>
    </div>
  </div>
</section>
