import { Injectable } from '@angular/core';
import { PostProcessFn, DistinctKey } from './ui-breadcrumb.model';

@Injectable()
export class UiBreadcrumbsConfig {
  postProcess: PostProcessFn | null = null;
  applyDistinctOn: DistinctKey | null = 'label';

  constructor(
    options: UiBreadcrumbsConfig = {
      postProcess: null,
      applyDistinctOn: 'label',
    }
  ) {
    if (!options) {
      return;
    }

    Object.keys(options).forEach((optKey) => {
      this[optKey] = options[optKey];
    });
  }
}
