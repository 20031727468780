import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { B2bUiFooterComponent } from './b2b-ui-footer.component';

@NgModule({
  declarations: [B2bUiFooterComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [B2bUiFooterComponent],
})
export class B2bUiFooterModule {}
