import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DownloadFormState, DownloadFormStore } from './download-form.store';

@Injectable({ providedIn: 'root' })
export class DownloadFormQuery extends Query<DownloadFormState> {

  constructor(protected store: DownloadFormStore) {
    super(store);
  }

}
