import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Tunnel } from './tunnels.model';

export interface TunnelsState {
  content: Tunnel;
}

function createInitialState(): TunnelsState {
  return {
    content: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tunnels' })
export class TunnelsStore extends Store<TunnelsState> {
  constructor() {
    super(createInitialState());
  }

  updateContent(content: Tunnel) {
    this.update({ content });
  }
}
