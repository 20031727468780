<header id="topnav" class="sticky" [class.nav-sticky]="isSticky" [class.opened]="isMenuOpened">
  <div class="container-xl" *ngIf="header$ | async as header">
    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.lt-md="start start"
      fxLayoutGap="15px"
      *ngIf="!isFormPage"
    >
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" ngClass.lt-md="w-100">
        <!-- Logo -->
        <div fxFlex>
          <ng-container *ngTemplateOutlet="logo"></ng-container>
        </div>

        <!-- Menu mobile -->
        <div class="menu-extras" fxHide fxShow.lt-md>
          <div class="menu-item">
            <button class="nav-toggle" (click)="toggleMenu()">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between center"
        fxLayoutAlign.lt-md="start start"
        fxLayoutGap="15px"
        fxHide.lt-md
        [fxShow.lt-md]="isMenuOpened"
      >
        <!-- Navigation -->
        <div class="navigation" fxFlex>
          <div class="navigation-menu" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="12px">
            <ng-container *ngFor="let item of header?.menu; trackBy: trackByFn">
              <a class="nav-link" [routerLink]="[item.url]" routerLinkActive="active">{{ item.text }}</a>
            </ng-container>
          </div>
        </div>

        <!-- Buttons -->
        <div class="buttons" ngClass.lt-md="py-3" fxFlex>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center" fxLayoutGap="24px">
            <ng-container *ngFor="let item of header?.ctas; trackBy: trackByFn">
              <a class="btn btn-rounded" [ngClass]="item.classes" [href]="item.url">
                <span>{{ item.text }}</span>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Form Page -->
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isFormPage">
      <ng-container *ngTemplateOutlet="logo"></ng-container>
    </div>
  </div>
</header>

<ng-template #logo>
  <a class="logo" [routerLink]="[(header$ | async)?.logoLink]">
    <img class="logo-image" [src]="(header$ | async)?.logo?.url" [alt]="(header$ | async)?.logo?.alternativeText" />
  </a>
</ng-template>
