import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {
  AdvantageTypeService,
  CtaSeoService,
  HomepageService,
  LivreBlancService,
  PageSeoService,
  PricingService,
  SharedService,
  TemoignageService,
} from '@b2b-app/b2b-core';
import { forkJoin, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class B2bUiDataResolver implements Resolve<any[]> {
  constructor(
    private sharedService: SharedService,
    private homepageService: HomepageService,
    private pricingService: PricingService,
    private advantageTypeService: AdvantageTypeService,
    private temoignageService: TemoignageService,
    private pageSeoService: PageSeoService,
    private livreBlancService: LivreBlancService,
    private ctaSeoService: CtaSeoService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any[]> | Promise<any[]> | any[] {
    return forkJoin([
      this.homepageService.get().pipe(
        switchMap(() => this.pageSeoService.find()),
        switchMap(() => this.livreBlancService.get()),
        switchMap(() => this.temoignageService.get())
      ),
      this.sharedService.get().pipe(switchMap(() => this.ctaSeoService.getCtaSeo())),
      this.advantageTypeService.getList().pipe(switchMap(() => this.pricingService.get())),
    ]);
  }
}
