import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UiBreadcrumb } from './ui-breadcrumb.model';
import { UiBreadcrumbsService } from './ui-breadcrumbs.service';

@Component({
  selector: 'b2b-ui-breadcrumbs',
  templateUrl: './ui-breadcrumbs.component.html',
  styleUrls: ['./ui-breadcrumbs.component.scss'],
})
export class UiBreadcrumbsComponent implements OnInit {
  crumbs$: Observable<UiBreadcrumb[]> = this.breadcrumbService.getCrumbs();

  constructor(private breadcrumbService: UiBreadcrumbsService) {}

  ngOnInit(): void {}
}
