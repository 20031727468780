import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Homepage } from './homepage.model';

export interface HomepageState {
  content: Homepage;
}

function createInitialState(): HomepageState {
  return {
    content: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'homepage' })
export class HomepageStore extends Store<HomepageState> {
  constructor() {
    super(createInitialState());
  }

  updateContent(content: Homepage) {
    this.update({ content });
  }
}
